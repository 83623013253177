import React from "react"

import Layout from "../components/layouts/layout"
import SEO from "../components/seo"
import { useHeroAssets } from "../hooks/hero"

const NotFoundPage = () => {
  const { hero } = useHeroAssets()
  return (
    <Layout
      heroOptions={{
        title: <span>Az oldal nem található</span>,
        bg: hero,
      }}
    >
      <SEO title="404: Sajnos nem található" />
    </Layout>
  )
}

export default NotFoundPage
